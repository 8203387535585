@use "easing" as easing

$SCREEN_PADDING: var(--spacing-16)
$CONTENT_MAX_WIDTH: 980px

@keyframes appear
  0%
    opacity: 0
    transform: translateY(10%)
  100%
    transform: translateY(0)
    opacity: 1

@keyframes slide
  0%
    opacity: 0
    transform: translateX(10%)
  100%
    transform: translateX(0)
    opacity: 1

.head
  grid-template-rows: 1fr
  transition: grid-template-rows 0.3s
  display: grid
  overflow: hidden
  .overflowContainer
    opacity: 1
    transition: opacity 0.2s
    overflow: hidden
    display: grid
    > *
      min-height: 250px
      max-height: min(600px, 45svh)
      grid-area: 1/1/2/2
      @media (max-width: 980px)
        max-height: max(600px, 45svh)

  .title
    display: flex
    flex-direction: column
    justify-content: flex-end
    gap: var(--spacing-8)
    box-sizing: border-box
    max-width: $CONTENT_MAX_WIDTH
    width: 100%
    margin: 0 auto
  .badge
    display: flex
    gap: var(--spacing-8)
    align-items: center
  .image
    object-fit: cover
    background-color: #EAE1C7 //image background color
    width: 100%
    height: 100%
    aspect-ratio: 16/9
    @media (max-width: 980px)
      aspect-ratio: 1 / 1
  .header
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: var(--spacing-24)
    @media (max-width: 980px)
      padding-top: var(--spacing-8)
  .link
    color: var(--color-materials-rubber)
    backdrop-filter: blur(20px)
    z-index: 2
    position: fixed
    top: var(--spacing-24)
    left: 40px
    padding: var(--spacing-8)
    display: inline-block
    background: #FBFBFA3D
    border-radius: var(--spacing-12)
    @media (max-width: 980px)
      top: var(--spacing-8)
      left: var(--spacing-24)
    svg
      display: block
  .logo
    height: 40px
    align-items: center
    display: flex
    justify-content: center

.MultiSiteMonthView
  display: grid
  height: 100%
  background: var(--color-materials-porcelain)
  overflow: auto
  overscroll-behavior: none

  .container
    display: grid
    height: 100%
    background: var(--color-materials-porcelain)
    grid-template-rows: auto 1fr
    overflow: hidden

    @media (max-width: 980px)
      overflow: initial
      &[data-day-present=true]
        overflow: hidden
        .head
          grid-template-rows: 0fr
          .overflowContainer
            opacity: 0
          .link
            display: none

  .ctaContent
    width: 100%
    margin: 0 auto
    max-width: $CONTENT_MAX_WIDTH
    position: relative

  .body
    flex: 1
    width: 100%
    overflow: hidden
    margin: 0 auto
    max-width: $CONTENT_MAX_WIDTH
    display: grid

  .calendarContainer
    display: grid

    .calendar
      transition: all 0.3s ease-in
      grid-area: 1/1/2/2

.locations
  margin: $SCREEN_PADDING auto
  max-width: $CONTENT_MAX_WIDTH
  overflow: auto
  width: 100%
  @media (max-width: 980px)
    margin: var(--spacing-12) auto var(--spacing-16)

  ul
    list-style-type: none
    margin: 0
    display: flex
    gap: var(--spacing-12)
    overflow-x: auto
    padding: 0 $SCREEN_PADDING
    -ms-overflow-style: none
    scrollbar-width: none
    &::-webkit-scrollbar
      display: none

  button
    background: transparent
    white-space: nowrap
    border-radius: var(--spacing-4)
    border: 1px solid var(--color-steel-grey-300)
    cursor: pointer
    display: flex
    padding: var(--spacing-8)
    justify-content: center
    align-items: center
    display: flex
    color: var(--color-materials-rubber)
    font-size: 12px
    font-weight: 500
    line-height: 14px
    letter-spacing: 0.6px
    text-transform: uppercase
    transition: all 0.15s ease-in
    &[data-selected=true]
      border-color: var(--color-intelligent-blue-600)
      background: var(--color-intelligent-blue-50)
      color: var(--color-intelligent-blue-900)

.mobileBack
  padding: var(--spacing-8) $SCREEN_PADDING
  width: 100%
  margin: 0 auto
  max-width: $CONTENT_MAX_WIDTH
  display: none
  gap: var(--spacing-12)
  align-items: center
  animation-name: slide
  animation-duration: 0.2s
  animation-delay: 0.2s
  animation-fill-mode: both

  .day
    text-transform: capitalize

  @media (max-width: 980px)
    &[data-active=true]
      display: flex

  img
    width: 100%
    height: 100%
    display: block
  .text
    display: flex
    flex-direction: column
    gap: var(--spacing-8)
    position: absolute
    bottom: var(--spacing-24)
    left: $SCREEN_PADDING
    right: $SCREEN_PADDING
  .locationCount
    display: flex
    align-items: center
    gap: var(--spacing-8)

.calendars
  display: grid
  overflow: hidden
  .calendar
    grid-area: 1/1/2/2
    transition: all 0.3s easing.$neko-45-75

.navHeader
  margin: var(--spacing-12) 0 var(--spacing-24) 0
  display: flex
  justify-content: space-between
  nav
    display: flex
    gap: var(--spacing-16)

.monthHeader
  display: grid
.monthName
  text-transform: capitalize
  grid-area: 1/1/2/2
  transition: all 0.3s easing.$neko-45-75
  color: #262C2E
  font-size: 20px
  font-style: normal
  font-weight: 400
  line-height: 24px
  letter-spacing: -0.4px

.bookingSection
  padding: 0 $SCREEN_PADDING
  overflow: hidden
  gap: var(--spacing-24)
  display: grid
  grid-auto-columns: 1fr
  grid-auto-flow: column
  @media (max-width: 980px)
    padding: 0
    > *
      grid-area: 1/1/2/2

.dayCalendar
  isolation: isolate
  overflow: auto
  display: flex
  flex-direction: column
  pointer-events: none
  &[data-active=true]
    pointer-events: initial
    background: var(--color-materials-porcelain)
    @media (max-width: 980px)
      background: var(--color-materials-light-aluminium)
  .time
    text-transform: uppercase
    padding: 0 $SCREEN_PADDING
    @media (max-width: 980px)
      padding: var(--spacing-24) $SCREEN_PADDING var(--spacing-8)

  .time, .dayTimes
    animation-name: appear
    animation-duration: 0.7s
    animation-delay: 0.3s
    animation-fill-mode: both

.cta
  padding: var(--spacing-16) $SCREEN_PADDING
  border-top: 1px solid var(--color-steel-grey-300)
  background: var(--color-materials-porcelain)
  backdrop-filter: blur(20px)
  width: 100%

.chevron
  transition: opacity 0.3s ease-in
  &[data-disabled=true]
    opacity: 0.2

.backButton
  color: var(--color-materials-rubber)
  cursor: pointer
  height: 40px
  width: 40px
  box-sizing: border-box
  border: none
  padding: var(--spacing-8)
  display: flex
  align-items: center
  justify-content: center
  border-radius: var(--spacing-12)
  &[data-variant='light']
    background: #FBFBFA3D
  &[data-variant='dark']
    background: var(--color-intelligent-blue-50)

.monthCalendar
  @media (max-width: 980px)
    padding: 0 $SCREEN_PADDING $SCREEN_PADDING
    &[data-collapse-for-mobile=true]
      display: none

.venue
  overflow: hidden

.weeklyCalendar
  display: none
  overflow: hidden
  ul
    display: flex
    flex-direction: row
    gap: $SCREEN_PADDING
    margin: 0
    padding: 0 $SCREEN_PADDING
    list-style: none
    overflow: auto
    scroll-snap-type: x mandatory

    -ms-overflow-style: none
    scrollbar-width: none
    &::-webkit-scrollbar
      display: none
    li
      flex-shrink: 0
      flex-grow: 1
      width: 100%
      > *
        scroll-snap-stop: always
        scroll-snap-align: center

  @media (max-width: 980px)
    display: initial

.chevronContainer
  display: flex
  justify-content: center
  align-items: center
  height: var(--spacing-24)
  width: var(--spacing-24)

.bookingContainer
  flex: 1
  display: flex
  flex-direction: column
  overflow: hidden

.content
  display: flex
  flex-direction: column
  overflow: hidden

.head, .bookingContainer
  position: relative
  &::after
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0
    background: var(--color-materials-rubber)
    transition: opacity 0.3s ease-in
    pointer-events: none

  &[data-display-overlay=true]
    &::after
      opacity: 0.24
      pointer-events: initial
