.DoctorsNote
  display: grid
  gap: var(--spacing-16)
  .header
    font-weight: 500
    color: 4c6a70
    font-size: 12px
    letter-spacing: 0.6px
    text-transform: uppercase
    line-height: 1.2
    text-align: left
