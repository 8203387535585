@use "easing" as easing

@keyframes fade-in
  0%
    pointer-events: none
    opacity: 0
    transform: translateX(24px)
  75%
    opacity: 1
  99%
    pointer-events: initial
  100%
    transform: translateY(0px)

@mixin fade-in-animation
  animation-name: fade-in
  animation-duration: 0.7s
  animation-fill-mode: both
  animation-timing-function: easing.$neko-0-75

.header
  position: sticky
  top: 0
  backdrop-filter: blur(20px)
  z-index: 1

.backButton
  @include fade-in-animation
  animation-duration: 0.3s

.CardPaymentView
  display: flex
  flex-direction: column
  min-height: 100%
  gap: var(--spacing-36)
.body
  flex: 1
  display: flex
.content
  @include fade-in-animation

  display: flex
  flex-direction: column
  gap: var(--spacing-36)
  margin: auto
  max-width: 540px
  width: 100%
  @media (max-width: 980px)
    margin: 0 var(--spacing-24) var(--spacing-24)
    max-width: 100%

.description
  display: grid
  gap: var(--spacing-12)

.details
  display: grid
  gap: var(--spacing-24)

.form
  overflow: hidden
  position: relative
  display: grid
  &[data-ready="true"]
    .paymentForm
      position: initial
      opacity: 1
      pointer-events: initial
  .paymentForm
    position: absolute
    transition: opacity 0.8s easing.$neko-0-75
    opacity: 0
    pointer-events: none
  > *
    grid-area: 1/1/2/2

.total
  display: flex
  justify-content: space-between
.price
  display: flex
  gap: var(--spacing-8)

.cta
  padding-top: 34px
  @media (max-width: 980px)
    margin-top: auto

.icon
  height: 40px
  display: flex
  justify-content: center
  align-items: center
