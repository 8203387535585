@use 'src/easing' as easing

.card
  width: 100%
  display: flex
  flex-direction: column
  overflow: hidden

  @keyframes transitionCardHeight
    from
      // note(ford): Slightly bigger than the screen to hide the yellow footer
      height: 120vh
    to
      height: var(--card-height)
      border-radius: 15px

  &:not([data-is-animated="true"])
    height: var(--card-height)
    border-radius: 15px

  &[data-is-animated="true"]
    animation-name: transitionCardHeight
    animation-duration: var(--duration)
    animation-timing-function: easing.$neko-31-40
    animation-play-state: var(--play-state)
    animation-fill-mode: both

    .logo
      visibility: hidden

    .header
      animation-name: headerTransition
      animation-delay: var(--delay)
      animation-duration: var(--duration)
      animation-timing-function: easing.$neko-31-40
      animation-play-state: var(--play-state)
      animation-fill-mode: both

    .cardFooter
      animation-name: fadeInFooter,
      animation-delay: var(--delay)
      animation-duration: var(--duration)
      animation-timing-function: easing.$neko-31-40
      animation-play-state: var(--play-state)
      animation-fill-mode: both

  .content
    display: grid
    height: 100%
    overflow: hidden

    > *
      grid-area: 1/1

    .bg
      object-fit: cover
      object-position: center
      width: 100%
      height: 100%
      border-top-left-radius: 0
      border-top-right-radius: 0
      overflow: hidden

    .body
      padding: 28px 28px var(--spacing-16) 28px
      background-size: cover
      background-position: center
      display: flex
      flex-direction: column
      justify-content: space-between

      .logo
        display: flex
        place-content: center
        color: var(--color-materials-porcelain)

        svg
          height: 14px

      .header
        margin: auto
        text-align: center

  &Footer
    background: #ffff78
    display: grid
    grid-template-columns: repeat(2, auto)
    padding: 20px 28px
    gap: 2px

    span
      font-family: "IBM Plex Mono"
      font-size: 12px
      font-style: normal
      font-weight: 400
      line-height: 14px
      letter-spacing: -0.12px
      text-transform: uppercase
      font-variant-numeric: slashed-zero

      &:nth-child(even)
        text-align: right

@keyframes headerTransition
  0%
    opacity: 0
    transform: translateY(50px)
  15%
    opacity: 0
  100%
    opacity: 1
    transform: translateY(0)

@keyframes fadeInFooter
  from
    opacity: 0
  to
    opacity: 1
