$max-width: 1200px
$extra-small-break-point: 744px
$mobile-break-point: 980px
$root-padding: var(--spacing-24)

.AppointmentInfoSection
  display: grid
  position: relative
  gap: var(--spacing-52)
  @media (max-width: $mobile-break-point)
    gap: var(--spacing-36)

.head
  display: grid
  > *
    min-height: 250px
    max-height: 600px
    grid-area: 1/1/2/2
  .title
    display: flex
    flex-direction: column
    justify-content: flex-end
    gap: var(--spacing-8)
    box-sizing: border-box
    max-width: $max-width
    width: 100%
    margin: 0 auto
  .badge
    display: flex
    gap: var(--spacing-8)
    align-items: center
  .image
    object-fit: cover
    background-color: #EAE1C7 //image background color
    width: 100%
    height: 100%
    aspect-ratio: 16/9
    @media (max-width: $mobile-break-point)
      aspect-ratio: 1 / 1

  .header
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: $root-padding
    @media (max-width: $mobile-break-point)
      padding-top: var(--spacing-8)
  .link
    color: var(--color-materials-rubber)
    backdrop-filter: blur(20px)
    z-index: 2
    position: fixed
    top: var(--spacing-24)
    left: 40px
    padding: var(--spacing-8)
    display: inline-block
    background: #FBFBFA3D
    border-radius: var(--spacing-12)
    @media (max-width: $mobile-break-point)
      top: var(--spacing-8)
      left: var(--spacing-24)
    svg
      display: block
  .logo
    height: 40px
    align-items: center
    display: flex
    justify-content: center

.content
  padding: 0 $root-padding

.body
  box-sizing: border-box
  margin-left: auto
  margin-right: auto
  max-width: $max-width
  column-gap: 44px
  display: grid
  grid-template-columns: 3fr 2fr
  grid-template-rows: 1fr auto

  @media (max-width: $mobile-break-point)
    grid-template-columns: 1fr

.sectionDoctorImage
  padding: 0
  height: 386px
  margin: 60px 0 0px 0
  background-color: #EAE1C7 //image main color
  @media (max-width: $extra-small-break-point)
    margin: var(--spacing-36) calc(-1 * $root-padding) 0 calc(-1 * $root-padding)

  @media (max-width: $mobile-break-point)
    order: 1
    position: sticky
    top: 0

  img
    object-position: center
    object-fit: cover

.stepsSection
  grid-column: 2
  grid-row: span 5

  @media (max-width: $extra-small-break-point)
    margin-left: calc(-1 * $root-padding)
    margin-right: calc(-1 * $root-padding)

  @media (max-width: $mobile-break-point)
    z-index: 1
    order: 2
    grid-row: initial
    grid-column: 1

  .stepsContainer
    padding: var(--spacing-36) var(--spacing-24) var(--spacing-24) var(--spacing-24)
    border-radius: var(--spacing-16)
    background-color: var(--color-materials-porcelain)

  .stepsContent
    position: sticky
    top: 32px
    overflow-y: auto
    overflow-x: hidden

    @media (max-width: $mobile-break-point)
      overflow: initial
      background-color: var(--color-materials-porcelain)
      position: relative
      top: 0

.sectionLocation
  padding: 0
  margin-top: 32px
  @media (max-width: $mobile-break-point)
    order: 1

  .findUs
    font-weight: 500
    color: var(--color-steel-grey-700)
    font-size: 10px
    letter-spacing: 0.6px
    text-transform: uppercase
    line-height: 1.2
    margin-bottom: var(--spacing-4)
  a
    font-weight: 300
    font-style: normal
    color: #019dc9
    font-size: 24px
    letter-spacing: -0.6px
    line-height: 1.3
    margin-bottom: var(--spacing-8)
  .arival
    margin-top: var(--spacing-8)
    font-weight: 400
    color: var(--color-steel-grey-700)
    font-size: 14px
    letter-spacing: 0px
    line-height: 1.2

.sectionTime
  display: grid
  grid-template-columns: 1fr 1fr
  margin-bottom: 46px
  padding: 0
  gap: var(--spacing-12)
  @media (max-width: $mobile-break-point)
    order: 1
  .date
    flex: 1
    display: flex
    flex-direction: column
    gap: 2px
    text-transform: capitalize

  .hour
    flex: 1
    display: flex
    flex-direction: column
    gap: 2px

.separator
  width: 32px
  height: 1px
  display: block
  background-color: var(--color-steel-grey-400)
  @media (max-width: $mobile-break-point)
    order: 1
  @media (max-width: 500px)
    margin: 0

.explanation
  display: flex
  flex-direction: column
  gap: var(--spacing-24)
  margin-top: 0px
  padding: 32px var(--spacing-16) 140px var(--spacing-16)

.sectionFAQ
  display: flex
  flex-direction: column
  gap: var(--spacing-24)
  padding-top: var(--spacing-72)
  @media (max-width: $extra-small-break-point)
    margin-left: calc(-1 * $root-padding)
    margin-right: calc(-1 * $root-padding)
  @media (max-width: $mobile-break-point)
    margin-top: 0
    order: 3
    z-index: 1
    padding: var(--spacing-72) $root-padding 160px
    background-color: var(--color-materials-light-aluminium)

.sectionFAQList
  list-style-type: none
  margin: 0
  padding: 0
  margin-top: var(--spacing-24)

  .sectionFAQListItem
    padding: var(--spacing-12) 0
    display: flex
    align-items: baseline
    gap: var(--spacing-16)
    border-top: 1px solid var(--color-steel-grey-300)
    &:last-child
      border-bottom: 1px solid var(--color-steel-grey-300)

    ul
      padding-left: var(--spacing-16)
      list-style-type: disc

.mobileProfileButton
  display: none
  @media (max-width: $mobile-break-point)
    background-color: var(--color-materials-light-aluminium)
    order: 3
    display: initial
    margin-top: 20px
    border-radius: var(--spacing-16)
    position: fixed
    bottom: var(--spacing-16)
    z-index: 2
    left: $root-padding
    right: $root-padding

  @media (max-width: $extra-small-break-point)
    bottom: var(--spacing-16)

.tipsContainer
  padding: var(--spacing-36) $root-padding
  @media (max-width: $mobile-break-point)
    padding: var(--spacing-24) $root-padding 0
    background-color: var(--color-materials-light-aluminium)
