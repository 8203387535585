.AppointmentPrereqView
  width: 100%
  min-height: 100%
  display: flex
  flex-direction: column

  .content
    padding: var(--spacing-24)
    margin: auto
    max-width: 440px
    width: 100%
    flex: 1
    display: flex
    flex-direction: column
    gap: var(--spacing-24)
    justify-content: center
    @media (max-width: 980px)
      max-width: 100%
      margin: 0
      justify-content: space-between

    .top
      display: grid
      gap: var(--spacing-24)

    .text
      display: grid
      gap: var(--spacing-12)

  .header
    position: sticky
    top: 0
    backdrop-filter: blur(20px)

  .steps
    margin: 0
    padding: 0
    display: flex
    flex-direction: column
    gap: var(--spacing-16)

    .step
      display: flex
      gap: var(--spacing-12)
      align-items: center
