.StepsSection
  width: 100%

  .todoList
    font-weight: 400
    color: var(--color-materials-rubber)
    margin: 20px 0 0 0
    padding: 0
    display: flex
    flex-direction: column
    gap: var(--spacing-16)

    li
      display: flex
      gap: var(--spacing-12)
      align-items: center

  .stepsHeader
    font-weight: 300
    font-style: normal
    color: var(--color-materials-rubber)
    font-size: 28px
    letter-spacing: -0.6px
    line-height: 1.3
    margin-bottom: var(--spacing-4)
  .stepsDescription
    font-weight: 400
    font-style: normal
    color: var(--color-steel-grey-700)
    font-size: 14px
    letter-spacing: 0px
    line-height: 1.2

  .stepsTips
    display: flex
    flex-direction: column
    gap: var(--spacing-16)
    padding: var(--spacing-24)
    @media (max-width: 980px)
      padding: var(--spacing-24) 0 0 0
    @media (max-width: 500px)
      padding: 32px var(--spacing-16)
    .stepsTipsHeader
      text-align: left
      font-weight: 500
      color: var(--color-steel-grey-700)
      font-size: 10px
      letter-spacing: 0.6px
      text-transform: uppercase
      line-height: 1.2
    .stepsTipsList
      list-style-type: none
      margin: 0
      padding: 0
      font-weight: 400
      font-style: normal
      color: var(--color-materials-rubber)
      font-size: 14px
      letter-spacing: -0.4px
      line-height: 1.3
      text-align: left
      display: flex
      flex-direction: column
      gap: var(--spacing-16)
      svg
        flex-shrink: 0
      li
        gap: var(--spacing-8)
        display: flex
        align-items: center

.profileButton
  margin-top: 20px
  @media (max-width: 980px)
    display: none

.chevron
  fill: var(--color-intelligent-blue-600)
