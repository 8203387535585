.BloodValuesForm
  overflow: auto
  height: 100%
  width: 100%
  transition: background 0.5s

  &[data-mode="day"], &[data-mode="final"]
    background: var(--color-materials-light-aluminium)
    .header
      color: var(--color-steel-grey-900)
    .subHeader
      color: var(--color-steel-grey-700)
    input
      color: var(--color-materials-rubber)
      &::placeholder  /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--color-steel-grey-400)
        opacity: 1 /* Firefox */
      &::-ms-input-placeholder  /* Internet Explorer 10-11 */
        color: var(--color-steel-grey-400)

  &[data-mode="night"]
    background: #263038
    .header
      color: #fbfbfa
    .subHeader
      color: #c5d9dd
    input
      color: #fbfbfa
      &::placeholder  /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--color-steel-grey-900)
        opacity: 1 /* Firefox */
      &::-ms-input-placeholder  /* Internet Explorer 10-11 */
        color: var(--color-steel-grey-900)

  .formContainer
    display: grid

  form
    font-family: "Neko Numeral Text", sans-serif
    grid-column: 1
    grid-row: 1
    display: grid
    grid-template-columns: 1fr auto 1fr
    gap: var(--spacing-12)
    flex-direction: row
    justify-content: center

  .inputContainer
    width: 100%
    &:last-of-type
      input
        text-align: left
    &:first-of-type
      input
        text-align: right

  input
    height: 100%
    font-size: 38px
    letter-spacing: 0em
    line-height: 1.2
    width: 100%
    border: none
    background: transparent
    transition: font-size 0.3s, color 0.3s
    color: var(--color-materials-rubber)
    &:last-of-type
      text-align: left
    &:first-of-type
      text-align: right
    &.active
      font-size: 64px
      color: var(--color-intelligent-blue-600)

  .inputSepparator
    color: var(--color-steel-grey-400)
    font-size: 38px
    display: flex
    justify-content: center
    align-items: center

  .container
    height: 100%
    display: flex
    flex-direction: column
    gap: var(--spacing-24)
    justify-content: space-between

.content
  padding: 0
  @media (max-width: 980px)
    padding: 0 var(--spacing-16)

.headings
  display: flex
  flex-direction: column
  gap: var(--spacing-8)
  height: 100%
  justify-content: center

.header
  font-weight: 300
  font-family: "IBM Plex Sans Regular", sans-serif
  color: var(--color-steel-grey-900)
  font-size: 36px
  letter-spacing: -1px
  line-height: 1.2
  text-align: center
  transition: background 0.5s

.subHeader
  font-weight: 400
  font-style: normal
  font-family: "IBM Plex Sans Regular", sans-serif
  color: var(--color-steel-grey-700)
  font-size: 14px
  letter-spacing: 0em
  line-height: 18px
  text-align: center
  transition: background 0.5s

.actions
  padding: 0 44px 44px
  display: grid
  overflow: hidden
  .action
    grid-column: 1
    grid-row: 1
    transition: all 0.3s
    z-index: 20
    &.active
      transition-delay: 0.3s
      opacity: 1
      pointer-events: initial
      transform: translateY(0)
    &.hidden
      opacity: 0
      pointer-events: none
      transform: translateY(100%)
  @media (max-width: 980px)
    padding: 0 var(--spacing-16) var(--spacing-16)

.modeContainer
  display: grid
  .step
    grid-column: 1
    grid-row: 1
    transition: opacity 0.3s, grid-template-rows 0s
    display: grid
    overflow: hidden
    &.active
      grid-template-rows: 1fr
      transition-delay: 0.3s, 0.3s
      opacity: 1
      pointer-events: initial
    &.hidden
      grid-template-rows: 0fr

      opacity: 0
      pointer-events: none

    .stepContent
      min-height: 0

.hiddenSubmitButton
  display: none
