.FreeText
  display: grid
  gap: var(--spacing-12)
  overflow: hidden

  // workaround @cur8/maneki <Typography> not accepting {className}
  > span
    display: contents

  *
    margin: 0

  ul
    display: grid
    gap: var(--spacing-4)
    padding: 0
    li
      display: flex
      gap: var(--spacing-16)
      position: relative
      &::before
        $_size: 6px
        background-color: var(--color-intelligent-blue-600)
        border-radius: 2px
        color: transparent
        content: '-'
        height: $_size
        left: 0
        position: relative
        top: 9px
        width: $_size

  h4:not(:first-of-type)
    margin-top: var(--spacing-12)
