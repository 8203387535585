.PopUpLayout
  box-shadow: 0px -2px rgba(251, 251, 250, 0.2)
  border-top-left-radius: 20px
  border-top-right-radius: 20px
  border-bottom-left-radius: 20px
  border-bottom-right-radius: 20px
  max-width: fit-content
  box-sizing: border-box
  padding: var(--spacing-24)
  background-color: var(--color-materials-porcelain)

  @media (max-width: 980px)
    border-bottom-left-radius: 0px
    border-bottom-right-radius: 0px
    max-width: 100%
