.LegalDocumentLayout
  width: 100%
  height: 100%
  background: var(--color-materials-light-aluminium)
  display: flex
  flex-direction: column
  overflow: auto
  .description
    // height of the header plus padding
    padding-top: calc(var(--spacing-52) + var(--spacing-72))
    background: var(--color-steel-grey-300)
    padding-bottom: var(--spacing-36)
  .content
    padding-top: var(--spacing-52)
    flex: 1
    background: var(--color-materials-light-aluminium)
  .actions
    max-width: 520px
    margin: 0 auto
    width: 100%
    padding: var(--spacing-36) 0 var(--spacing-36)
    &[data-sticky=true]
      position: sticky
      bottom: 0

  .actionContainer
    display: grid
    gap: var(--spacing-12)

  .container
    max-width: 720px
    margin: 0 auto
    width: 100%
    @media (max-width: 980px)
      max-width: 100%
      padding: 0 var(--spacing-24)
