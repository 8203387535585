.TipsSection
  display: flex
  flex-direction: column
  gap: var(--spacing-24)

  .stepsTipsList
    list-style-type: none
    margin: 0
    padding: 0
    display: flex
    flex-direction: column
    gap: var(--spacing-16)
    svg
      flex-shrink: 0
    li
      gap: var(--spacing-12)
      display: flex
      align-items: center
