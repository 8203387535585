.header
  display: flex
  gap: var(--spacing-12)
  padding: var(--spacing-16) var(--spacing-24)
  place-content: space-between
  place-items: center
  width: 100%
  @media (min-width: 980px)
    padding: var(--spacing-24) 40px

  &[data-centered="true"]
    .left, .right
      flex: 1

  &[data-sticky="true"]
    backdrop-filter: blur(6px)
    position: sticky
    top: 0
    z-index: 1

.left, .right
  display: flex
  min-height: var(--spacing-24)
  min-width: 0

.right
  place-content: flex-end

.left
  place-content: flex-start
