.StepButton
  display: flex
  width: 100%
  justify-content: flex-start
  background: transparent
  border: none
  margin: 0
  padding: 0
  cursor: pointer
  display: flex
  gap: var(--spacing-12)
  align-items: center

  &[data-can-click=false]
    cursor: initial

  &:disabled
    pointer-events: none
    cursor: inherit
    color: inherit
    .circle
      border-color: var(--color-materials-aluminium)
    .chevron
      display: none

  &[data-completed=false][data-can-click=true]
    @media (hover: hover)
      &:hover
        .circle
          height: var(--spacing-8)
          width: var(--spacing-8)
          border-radius: 0
          background: var(--color-materials-aluminium)
          border-color: var(--color-materials-aluminium)
          svg
            display: none

  .circle
    transition: all 0.4s
    display: flex
    justify-content: center
    align-items: center
    height: var(--spacing-24)
    width: var(--spacing-24)
    border-radius: var(--spacing-8)
    box-sizing: border-box
    border: 1px solid var(--color-steel-grey-400)
    svg
      display: none
      path
        stroke: var(--color-steel-grey-700)

  &[data-completed=true]
    pointer-events: none
    .chevron
      visibility: hidden

    .circle
      background-color: var(--color-materials-aluminium)
      border-color: var(--color-materials-aluminium)
      svg
        display: initial

  .todoText
    flex: 1
    text-align: left
  .iconContainer
    height: var(--spacing-24)
    width: var(--spacing-24)
    display: flex
    justify-content: center
    align-items: center

  .chevron
    stroke: var(--color-materials-rubber)
