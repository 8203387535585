
.pageHeader
  display: flex
  padding: 20px 20px var(--spacing-16) 20px
  align-items: center
  position: relative

  .closeButton
    position: absolute
    left: 0
    padding: 20px
    cursor: pointer

.text
  display: grid
  gap: 20px
  margin-top: 46px

.header
  color: #263032
  font-family: Orleans Roman
  font-size: 48px
  font-style: normal
  font-weight: 400
  line-height: normal
  letter-spacing: -1.44px

.subHeader
  margin: 0
  padding: 0
  color: #263032
  font-family: "IBM Plex Sans Regular"
  font-size: 36px
  font-style: normal
  font-weight: 300
  line-height: 40px
  letter-spacing: -1.08px
