.toast
  margin: var(--spacing-24) 20px auto auto
  transform: translateX(calc(100% + 20px))
  transition: transform 0.5s ease

  @media (max-width: 980px)
    margin: auto 0 0 0
    transform: translateY(calc(100% + 20px))

  [data-active=true] &
    transition-delay: 0.1s
    transform: initial
