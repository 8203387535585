.Instructions
  margin: 0
  padding: 0
  list-style: none
  @media (max-width: 980px)
    margin: 0 calc(-1 * var(--spacing-24))
    grid-template-columns: 1fr
  li
    padding: var(--spacing-24) 0
    border-bottom: 1px solid #c5d9dd
    @media (max-width: 980px)
      padding: var(--spacing-24)

    b
      font-weight: 500
    button
      border: none
      margin: 0
      padding: 0
      background: transparent
      cursor: pointer
      color: var(--color-intelligent-blue-600)
      font-size: 14px
      letter-spacing: 0em
      line-height: 1.2
      font-weight: 400

.header
  position: relative
  font-weight: 300
  color: #263032
  font-size: 24px
  letter-spacing: -0.6px
  line-height: 1.3

.explanation
  display: flex
  flex-direction: column
  gap: var(--spacing-12)
  cursor: initial
  color: #4c6a70
  font-size: 14px
  letter-spacing: 0em
  line-height: 1.2
  font-weight: 400

.bolded
  font-weight: 500
