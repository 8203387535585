.BlurLayer
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 999
  .blur
    background-color: rgba(255, 255, 255, 0.3)
    backdrop-filter: blur(20px)
    transition: opacity 0.5s
  .blur, .content
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
