.AssignmentView
  height: 100%
  width: 100%
  overflow: auto
  .items
    height: 100%
    width: 100%
    display: grid
    .item
      opacity: 0
      grid-column: 1
      grid-row: 1
      transition: opacity 0.3s, grid-template-rows 0s
      display: grid
      grid-template-rows: 0fr
      pointer-events: none
      &.active
        pointer-events: initial
        transition-delay: 0.15s
        opacity: 1
        grid-template-rows: 1fr

  .body
    width: 100%
    display: grid
    column-gap: 44px
    grid-template-columns: 1fr
    max-width: 1020px
    margin: 66px auto 44px
    @media (max-width: 980px)
      padding: 0 var(--spacing-24)
      grid-template-columns: 1fr

.sticky
  position: sticky
  z-index: 1
  top: 0
  backdrop-filter: blur(5px)

.boxContainer
  position: relative

  .box
    display: grid
    gap: var(--spacing-24)
    transform: translateY(-50%)
    position: fixed
    width: 376px
    top: 50%
    padding: var(--spacing-36) var(--spacing-24) var(--spacing-36) var(--spacing-24)
    background-color: #f0f0f0
    border-radius: var(--spacing-16)
    @media (max-width: 980px)
      padding: 0 0 var(--spacing-24)
      background-color: transparent
      width: 100%
      transform: initial
      position: initial

    h2
      font-weight: 500
      color: #4c6a70
      font-size: 12px
      letter-spacing: 0.6px
      text-transform: uppercase
      line-height: 1.2
      text-align: left

    ol
      margin: 0
      list-style-type: none
      display: grid
      gap: var(--spacing-24)
      li
        font-weight: 400
        font-style: normal
        color: #4c6a70
        font-size: 14px
        letter-spacing: -0.4px
        line-height: 1.3
        text-align: left

.cta
  position: sticky
  bottom: 0
  padding: var(--spacing-16) 0
  display: none

  @media (max-width: 980px)
    display: initial
