.ProgressDays
  display: grid
  gap: var(--spacing-24)
  margin: var(--spacing-24) 0
  @media (max-width: 980px)
    margin: var(--spacing-24) calc(-1 * var(--spacing-24))

  .values
    display: grid
    grid-template-columns: minmax(30px, auto) repeat(7, 1fr) minmax(30px, auto)
    .data
      grid-column: span 7
      display: grid
      grid-template-columns: repeat(7, 1fr)

.navButton
  cursor: pointer
  border: none
  background: none
  padding: 0
  margin: 0
  opacity: 1
  transition: opacity 0.3s
  &:disabled
    opacity: 0
    pointer-events: none
    visibility: hidden
    cursor: initial
