.MetricGroup
  display: flex
  flex-direction: column
  gap: var(--spacing-16)

  &[data-kind="group"]
    margin-top: var(--spacing-16)

.cards
  display: grid
  gap: var(--spacing-12)
  grid-template-columns: 1fr 1fr

  @media (max-width: 1440px)
    grid-template-columns: 1fr

  @media (max-width: 980px)
    grid-template-columns: 1fr 1fr

  @media (max-width: 744px)
    grid-template-columns: 1fr
