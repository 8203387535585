.container
  display: flex
  flex-direction: column
  background: var(--color-materials-porcelain)
  border-radius: var(--spacing-16)
  padding: var(--spacing-16) var(--spacing-24)
  align-items: center
  gap: var(--spacing-8)
  transition: border-radius 0.6s

  @media (hover: hover)
    &:hover
      border-radius: unset

.header
  display: flex
  width: 100%
  flex-direction: row
  align-items: center
  justify-content: space-between

.labelWrapper
  display: flex
  flex-direction: row
  align-items: center
  gap: var(--spacing-8)

.content
  display: flex
  width: 100%
  flex-direction: row
  justify-content: space-between
  align-items: baseline
  gap: 20px
  align-items: flex-end

.values
  display: flex
  align-items: baseline
  gap: var(--spacing-8)

.skeleton
  height: var(--spacing-36)

.note
  display: flex
  flex-wrap: wrap
  gap: var(--spacing-4)
  justify-content: flex-end
