.ContactUsPopup
  display: grid
  gap: var(--spacing-12)
  max-width: 358px
  @media (max-width: 980px)
    max-width: 100%

.header
  display: flex
  justify-content: space-between
  text-align: left
  @media (max-width: 980px)
    justify-content: center
    text-align: center
  .close
    display: initial
    @media (max-width: 980px)
      display: none

.body
  display: flex
  justify-content: center
  align-items: flex-start
  overflow: auto

.content
  width: 100%
  margin-top: auto
  margin-bottom: auto
  display: flex
  height: initial
  flex-direction: column
  gap: var(--spacing-24)

.info
  display: grid
  gap: var(--spacing-16)
  text-align: left
  @media (max-width: 980px)
    text-align: center

.cta
  .close
    display: none
    @media (max-width: 980px)
      display: initial
