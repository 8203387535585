@use 'src/easing' as easing

.stacked
    display: grid
    height: 100%
    width: 100%

    > *
        grid-area: 1 / 1
        height: 100%
        width: 100%

.content
    height: 100%
    width: 100%
    min-height: 100%
    overflow: auto
    scrollbar-gutter: stable
    display: flex
    flex-direction: column
    justify-content: center

    @media (max-width: 640px)
        justify-content: stretch

.closeButton
    color: var(--color-materials-rubber)
    position: absolute
    width: 40px
    height: 40px
    padding: 0
    outline: none
    border: none
    background: none

    top: var(--spacing-16)
    left: var(--spacing-16)

    @media (max-width: 640px)
        position: relative
        top: var(--spacing-24)
        left: var(--spacing-24)
        margin-bottom: var(--spacing-24)

    &:not(:disabled)
        cursor: pointer

    &:focus-visible
        outline: -webkit-focus-ring-color solid 1px

    .exIcon
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        color: var(--color-materials-rubber)
        opacity: 0.4

    .squircle
        width: 100%
        height: 100%

        path
            fill: var(--color-materials-rubber)
            fill-opacity: 0.04

.shareContainer
    flex-basis: 100%
    display: flex
    place-content: center
    place-items: center

    .shareContent
        display: flex
        flex-direction: column
        place-content: center
        gap: var(--spacing-24)
        width: 100%
        height: 100%
        max-width: 440px

        .content:not([data-is-animated="true"]) &
            padding: var(--spacing-36) var(--spacing-24) var(--spacing-36) var(--spacing-24)
            animation-name: slideInCard
            animation-duration: var(--duration)
            animation-timing-function: easing.$neko-30-30
            animation-play-state: var(--play-state)
            animation-fill-mode: both

        .content[data-is-animated="true"] &
            animation-name: transitionPadding
            animation-duration: var(--duration)
            animation-timing-function: easing.$neko-31-40
            animation-play-state: var(--play-state)
            animation-fill-mode: both

    .buttons
        display: flex
        flex-shrink: 0
        gap: var(--spacing-12)

        & > *
            flex: 1

        .content[data-is-animated="true"] &
            .shareButton
                animation-name: fadeButtons
                animation-delay: var(--delay)
                animation-duration: var(--duration)
                animation-timing-function: easing.$neko-31-40
                animation-play-state: var(--play-state)
                animation-fill-mode: both

            .continueButton
                animation-name: fadeButtons
                animation-delay: var(--delay)
                animation-duration: var(--duration)
                animation-timing-function: easing.$neko-31-40
                animation-play-state: var(--play-state)
                animation-fill-mode: both

.blur
    background-color: rgba(240, 240, 240, 0.60)
    backdrop-filter: blur(25px)
    width: 100%
    height: 100%

    animation-name: unblurify
    animation-duration: var(--duration)
    animation-timing-function: easing.$neko-31-40
    animation-play-state: var(--play-state)
    animation-fill-mode: both

.fullWidth
    width: 100%

.logo
    display: flex
    place-content: center
    position: absolute
    top: var(--top)
    height: var(--height)

    &:not([data-is-animated="true"])
        display: none

    &[data-is-animated="true"]
        animation-name: logoColorTransition
        animation-duration: var(--duration)
        animation-timing-function: easing.$neko-31-40
        animation-play-state: var(--play-state)
        animation-fill-mode: both

    svg
        height: 14px

@keyframes logoColorTransition
    0%
        color: var(--color-materials-rubber)
    100%
        color: var(--color-materials-porcelain)

@keyframes unblurify
    to
        backdrop-filter: blur(0)
        background-color: rgba(240, 240, 240, 0.0)
        visibility: hidden

@keyframes fadeButtons
    0%
        transform: scale(0.85)
        opacity: 0
    15%
        opacity: 0
    100%
        transform: scale(1)
        opacity: 1

@keyframes transitionPadding
    to
        padding: var(--spacing-24)

@keyframes slideInCard
    0%
        opacity: 0
        transform: translateY(60%)
    15%
        opacity: 0
    100%
        opacity: 1
        transform: translateY(0)
